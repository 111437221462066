import React, { useEffect, useLayoutEffect, useState } from "react"
import {useCookies} from "react-cookie";
import ReactGA from "react-ga";
import Modali, { useModali } from "../components/modal"
import Popup, {useModal} from "../components/popup"

import Header from "../components/pricing/header"
import Plans from "../components/pricing/plans"
import Demo from "../components/pricing/demo"
import Faq from "../components/pricing/faq"
import Footer from "../components/pricing/footer"
import SEO from "../components/seo"
import "../scss/style.scss"

export default function Pricing(props) {
  const [contactModal, toggleContactModal] = useModali();
  const [activeTab, setActiveTab] = useState(1);
  const [videoModal, toggleVideoModal] = useModali();
  const [cookies, setCookie] = useCookies(['pricingPagePopup']);
  const [modalOptions, toggleModal] = useModal();

  useEffect(() => {
    // setTimeout(openPopup, 10000);
  }, [])

  const openPopup = () => {
    if(!cookies.pricingPagePopup){
      ReactGA.event({
        category: 'POP-UP',
        action: 'Open Pricing Page Pop-up'
      });
      toggleModal();
    }
  }

  const toggleTab = (tab) => {
    if (tab === 1){
      window.open('/accounting-software-self-employed-soloplan', '_self')
    } else {
      window.open('/starter-standard-pricing-page', '_self')
    }
  }

  const page = 'SS Pricing Page';
  return (
    <div className='new_pricing_page'>
      <SEO title="Automate and Simplify your accounting business process FREE plan for Starters Ready to scale? $50/month. Try it free for 30 Days and get an extra 40 hours a month back!" einvoice={true} description="Automate and integrate tools to let you process invoices in less than 30 seconds. Imagine what you can do with an extra 40 hours a month!"
           keywords="Starter standard pricing page"/>
      <Header toggleTab={toggleTab} activeTab={2} page={page} />
      <Plans activeTab={2} page={page} />
      <Demo activeTab={2} page={page} />
      <Faq activeTab={2} page={page} />
      <Footer page={page} />
    </div>
  )
}
